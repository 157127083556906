import { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { DateTimeDuration } from '@common/utils/DateTimeDuration';
import { DateTimeUtil } from '@common/utils/DateTimeUtil';

interface CountDownProps {
    date: Date;
    hasTicking?: boolean;
    isBlock?: boolean;
}

interface CountdownTime {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export const useCalculatedCountdownTime = ({
    date,
    hasTicking,
    isBlock,
}: CountDownProps): CountdownTime | string => {
    const __date = useTranslation('components').t;

    const countDownDate = date.getTime();
    const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

    useEffect((): void | (() => void) => {
        if (hasTicking) {
            const interval = setInterval(() => {
                const newCountDown = countDownDate - new Date().getTime();
                setCountDown(newCountDown > 0 ? newCountDown : 0);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [countDown, countDownDate, hasTicking]);
    if (isBlock && countDown <= 0) {
        return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
    }
    const dateInterval: DateTimeDuration = new DateTimeDuration(date);

    if (isBlock) {
        return dateInterval;
    }

    return dateInterval.totalDays === 0
        ? DateTimeUtil.formatDuration({
              date,
              __translate: __date,
              includeSeconds: dateInterval.hours === 0,
              includeMinutes: dateInterval.minutes > 0,
              format: DateTimeUtil.DurationFormat.DURATION_SHORT,
          })
        : DateTimeUtil.format(date, DateTimeUtil.formats.TIME_NUMERIC);
};
