import { FC, memo } from 'react';
import Image from 'next/image';

import { Icon } from '@common/atoms/Icon';
import { Link } from '@common/atoms/Link/Link';
import {
    NotificationBar as NotificationBarEntry,
    NotificationBarCategory,
    PlatformID,
} from '@common/clients/api';
import { youtube } from '@common/regexp/youtube';
import { useContextData } from '@common/useContextData';
import { BettingDisclaimer } from '@web/atoms/BettingDisclaimer';
import { BettingIntegrationTracker } from '@web/atoms/BettingIntegrationTracker';
import { CountDownElement } from '@web/atoms/CountDownElement';
import { Marquee } from '@web/atoms/Marquee/Marquee';
import { ResponsiveToggle } from '@web/atoms/ResponsiveToggle';
import { PlatformToggle } from '@web/molecules/PlatformToggle';

import { ArrowRightBackground } from './atoms/ArroRightBackground';

import styles from './NotificationBar.module.scss';

/** Bookmark placeholder logo */
const BOOKMARK_PLACEHOLDER_LOGO = 'https://vp.cdn.pxr.nl/images/vp/logo-dark.svg';

export interface Props extends NotificationBarEntry {
    removeNotification: (id: number) => void;
}

export const ArrowRight = memo(function Component() {
    return (
        <>
            <PlatformToggle include={[PlatformID.GP]}>
                <Icon.arrowsRight />
            </PlatformToggle>
            <PlatformToggle exclude={[PlatformID.GP]}>
                <Icon.right />
            </PlatformToggle>
        </>
    );
});

const Component: FC<Props> = ({
    category,
    url,
    tag,
    duration,
    startTime,
    bookmaker,
    title,
    id,
    removeNotification,
}) => {
    const contextData = useContextData();
    const classes = ['NotificationBar', 'full-width', styles['notification-bar']];

    const isBetting = category === NotificationBarCategory.BETTING;
    const isRegular = category === NotificationBarCategory.REGULAR;
    const isLive = category === NotificationBarCategory.LIVE;
    const isCountDown = category === NotificationBarCategory.COUNTDOWN;

    const href = url ?? bookmaker?.url ?? '';
    const startsWithSlash = url && /^\//.test(url);
    const platformName = contextData?.platform?.name?.toLowerCase();
    const containsPlatform = url && platformName && new RegExp(platformName).test(url);
    const isInternalLink = startsWithSlash || containsPlatform;
    let imageUrl = '';
    if (url) {
        const matches: RegExpExecArray | null = youtube.exec(url);
        if (matches && matches.length > 1) {
            imageUrl = 'https://img.youtube.com/vi/' + matches[1] + '/maxresdefault.jpg';
        }
    }

    const startDateTime = new Date(startTime.toString());
    const countDownTime = new Date(startDateTime.getTime() + duration * 60 * 1000);
    const backgroundColor = bookmaker?.backgroundColor;

    return (
        <aside
            className={category}
            style={backgroundColor ? ({ '---backgroundColor': backgroundColor } as React.CSSProperties) : {}}
        >
            <div
                className={classes.join(' ')}
                onClick={(e) => {
                    if (e.target instanceof HTMLAnchorElement) {
                        return;
                    }
                    window.location.href = url ?? '';
                }}
            >
                <Link
                    id={String(id)}
                    href={href}
                    className={styles.link}
                    isSponsored={isBetting}
                    target={bookmaker && !isInternalLink ? '_blank' : undefined}
                >
                    {isLive ? (
                        <div className={styles['thumb-container']}>
                            <span className={'play-button'}>
                                <span className="icon"></span>
                            </span>
                            <Image
                                className={styles['image-thumb']}
                                alt={''}
                                src={imageUrl || ''}
                                width={0}
                                height={0}
                                unoptimized={true}
                                loader={({ src }) => src}
                            />
                        </div>
                    ) : null}
                    {!isBetting && !isRegular && !isLive && !isCountDown && category ? (
                        <span className={styles.category}>{tag ?? category}</span>
                    ) : null}
                    <h2>
                        <span className={styles.title}>
                            {isBetting && tag ? <span className="tag betting">{tag}</span> : null}
                            {isLive || isCountDown ? (
                                <div className={styles['live-status']}>
                                    <span className={styles['dot']} />
                                    {Boolean(bookmaker && tag) ? <span className="tag">{tag}</span> : null}
                                </div>
                            ) : null}
                            <ResponsiveToggle
                                mobile={
                                    <Marquee>
                                        <>
                                            <span className={styles.text}>{title}</span>
                                            <ArrowRight />
                                        </>
                                    </Marquee>
                                }
                                desktop={
                                    <>
                                        <span className={styles.text}>{title}</span>
                                        <ArrowRight />
                                    </>
                                }
                            />
                        </span>
                        {isBetting && bookmaker ? (
                            <Image
                                className={styles['bookmaker-logo']}
                                alt={bookmaker.name || ''}
                                src={bookmaker.darkBackgroundIcon || BOOKMARK_PLACEHOLDER_LOGO}
                                width={0}
                                height={0}
                                unoptimized={true}
                                loader={({ src }) => src}
                            />
                        ) : null}
                    </h2>
                    <footer>
                        {isCountDown ? <CountDownElement date={countDownTime} isBlock={true} /> : null}
                        {(isLive || isCountDown) && bookmaker ? (
                            <Image
                                className={styles['bookmaker-logo']}
                                alt={bookmaker.name || ''}
                                src={bookmaker.darkBackgroundIcon || BOOKMARK_PLACEHOLDER_LOGO}
                                width={0}
                                height={0}
                                unoptimized={true}
                                loader={({ src }) => src}
                            />
                        ) : null}
                    </footer>
                </Link>
                {isBetting ? (
                    <span
                        className={`${styles.disclaimer} ndm-betting-integration-tracker`}
                        data-tracker-name="notificationBar"
                    >
                        <BettingDisclaimer showEighteenPlusLogo color={'light'} />
                    </span>
                ) : null}
                <Icon.close
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        removeNotification(id);
                    }}
                />
                {bookmaker ? (
                    <BettingIntegrationTracker
                        bookmakerID={bookmaker?.id}
                        module={NotificationBarCategory.BETTING}
                    />
                ) : null}
            </div>

            <PlatformToggle include={[PlatformID.GP]}>
                <span className={`${styles['arrow-background']} ${category}`}>
                    <ArrowRightBackground />
                </span>
            </PlatformToggle>
        </aside>
    );
};

export const NotificationBar = memo(Component);
