import { FC, memo, PropsWithChildren, useEffect, useRef, useState } from 'react';

import useWindowSize from '@web/hooks/useWindowSize';

import styles from './Marquee.module.scss';

/** Add some extra space for the text wrapper so it doesn't show the marquee when it haves a close width to the view port */
const CONTAINER_SPACE = 150;

/** Define marquee speed multiplicator, adjust this to change the speed of the marquee */
const MARQUEE_SPEED_MULTIPLICATOR = 100;

/** Define marquee default speed, initially the marquee is disabled. */
const DEFAULT_SPEED = '0s';

interface Props extends PropsWithChildren {
    ieEnabledForShortTexts?: boolean;
}

const Component: FC<Props> = ({ ieEnabledForShortTexts = false, children }) => {
    const windowSize = useWindowSize();
    const marqueeRef = useRef<HTMLDivElement>(null);
    const [animationDuration, setAnimationDuration] = useState<string>(DEFAULT_SPEED);

    useEffect(() => {
        if (marqueeRef.current) {
            /** Calculate marquee speed based on text width, container width and a multiplicator MARQUEE_SPEED_MULTIPLICATOR */
            const textWidth = (
                marqueeRef.current.children[0]
                    ? (marqueeRef.current.children[0] as HTMLElement)
                    : marqueeRef.current
            ).offsetWidth;

            const itemWidth = marqueeRef.current.offsetWidth;

            /** Update marquee animation based on text width, because the text of the marquee can be longer than the container width*/
            document.documentElement.style.setProperty('--marquee-distance', `${-itemWidth}px`);

            if (textWidth + CONTAINER_SPACE > windowSize.width || ieEnabledForShortTexts) {
                const duration = ((windowSize.width + textWidth) / MARQUEE_SPEED_MULTIPLICATOR).toFixed(0);
                setAnimationDuration(`${duration}s`);
            } else {
                setAnimationDuration(DEFAULT_SPEED);
            }
        }
    }, [children, ieEnabledForShortTexts, windowSize]);

    return (
        <span className={`Marquee ${styles.Marquee}`}>
            <span className={styles['marquee-content']} style={{ animationDuration: animationDuration }}>
                <span className={styles['marquee-item']} key={`marque-text`} ref={marqueeRef}>
                    {children}
                </span>
                {animationDuration !== DEFAULT_SPEED ? (
                    <span className={styles['marquee-item']} key={`marque-text-after`}>
                        {children}
                    </span>
                ) : null}
            </span>
        </span>
    );
};

export const Marquee = memo(Component);
