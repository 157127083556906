import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { getCleanDate } from '@common/utils/DateTimeUtil';
import { useCalculatedCountdownTime } from '@web/hooks/useCountdownTime';

import styles from './CountDownElement.module.scss';

export interface Props {
    date: Date | string | number;
    hasTicking?: boolean;
    isBlock?: boolean;
}

export const CountDownElement: FC<Props> = ({ date: elementDate, hasTicking = true, isBlock }) => {
    const date = getCleanDate(elementDate);
    const countdown = useCalculatedCountdownTime({ date, hasTicking, isBlock });
    const __meta = useTranslation('meta').t;

    if (isBlock && typeof countdown !== 'string') {
        const { days, hours, minutes, seconds } = countdown;
        const container = days > 0 ? styles.CountDownElement : `${styles.CountDownElement} ${styles.big}`;
        return (
            <div className={container}>
                {days > 0 ? (
                    <p>
                        {days} <span className={styles['countdown-time']}>{__meta`countdown.days`}</span>
                    </p>
                ) : null}
                <p>
                    {hours}
                    <span className={styles['countdown-time']}>{__meta`countdown.hours`}</span>
                </p>
                <p>
                    {minutes} <span className={styles['countdown-time']}>{__meta`countdown.minutes`}</span>
                </p>
                <p>
                    {seconds}
                    <span className={styles['countdown-time']}>{__meta`countdown.seconds`}</span>
                </p>
            </div>
        );
    }

    return <>{countdown}</>;
};
